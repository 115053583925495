import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import LayoutBlog from "./layoutBlog"
import { Row, Col } from "react-bootstrap"
import BlogStyles from "../styles/blog.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faUser, faCalendarDay } from "@fortawesome/free-solid-svg-icons"

library.add(faUser, faCalendarDay)

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPost(sort: { fields: date, order: DESC }, filter: {node_locale: {eq: "en-US"}}) {
        edges {
          node {
            title
            slug
            excerpt
            author {
              name
              avatar {
                file {
                  url
                }
              }
            }
            createdAt(formatString: "MMMM Do, YYYY")
            date(formatString: "MMMM Do, YYYY")
            updatedAt
            hero {
              file {
                url
              }
            }
            body {
              fields {
                readingTime {
                  text
                  minutes
                  time
                  words
                }
              }
            }
          }
        }
      }
    }
  `)

  const dataPost = data.allContentfulPost

  return (
    <LayoutBlog>
      <Row>
        {dataPost.edges.map(blog => {
          return (
            <Col lg={12} key={blog.node.slug} className={BlogStyles.blogs}>
              <Row className="mb-5">
                <Col lg={4}>
                  <div>
                    <Link to={blog.node.slug}>
                      <img
                        src={blog?.node?.hero?.file?.url ? blog.node.hero.file.url : ''}
                        alt={blog?.node?.title ? blog.node.title : ''}
                        className={`${BlogStyles.blogImage} img-fluid`}
                      />
                    </Link>
                  </div>
                </Col>
                <Col lg={8}>
                  <div>
                    <Link to={blog?.node?.slug ? blog.node.slug : ''}>
                      <h3>{blog?.node?.title ? blog.node.title : ''}</h3>
                    </Link>
                    <p>{blog?.node?.excerpt ? blog.node.excerpt : ''}</p>
                    <p>
                      <span className="mr-4">
                        <strong><FontAwesomeIcon icon="calendar-day" />{blog?.node?.date ? blog.node.date : ''}</strong>
                      </span>
                      <span>
                        <strong><FontAwesomeIcon icon="clock" />{blog?.node?.body?.fields?.readingTime?.text ? blog.node.body.fields.readingTime.text : ''}</strong>
                      </span>
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          )
        })}
      </Row>
    </LayoutBlog>
  )
}

export default BlogPage
